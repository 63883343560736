<template>
  <div>
    <div class="header-mobile mobile">
      <i class="material-icons" @click="$router.back()">close</i>
    </div>
    <div class="trial-account-container container">
      <div class="text-center">
        <div class="h6 main">내 플랫폼 서비스의 개설 정보</div>
      </div>
      <div class="flex-center">
        <div style="width:349px;margin-top:40px">
          <div>
            <div class="body3-medium sub2">내 서비스 이름</div>
            <input class="input font-primary margin-top-8" type="text"
                   placeholder="텍스트" :class="{'input-error': vErr.name}"
                   style="width:100%" maxlength="30"
                   v-model="order.service_name"/>
            <div class="body5 sub4" style="margin-top:4px">*서비스 개설 후 수정할 수 있습니다</div>
          </div>
          <div style="margin-top:28px">
            <div class="body3-medium sub2">내 서비스 주소</div>
            <div class="flex-align margin-top-8 input-sub3"
                 :class="{'input-error': vErr.key}"
                 style="border-radius:8px">
              <input class="input font-primary" type="text"
                     placeholder="영문, 숫자" id="service-key" autocomplete="new-password"
                     style="width:100%;border:0" maxlength="30"
                     @input="onKeyupServiceId" @blur="validKey"
                     v-model="order.key"/>
              <div style="padding:0 16px 0 0">.launchpack.co.kr</div>
            </div>
            <div class="err" v-if="err!==''">{{ err }}</div>

            <div class="body5 sub4" style="margin-top:4px">*서비스 주소 영문명은 변경되지 않습니다</div>
          </div>
          <button class="button is-primary body2-medium" @click="clickOrder"
                  style="width:100%;height:52px;margin-top:40px">7+7일 무료체험 시작하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";

  export default {
    name: "TrialAccount",
    mixins: [
      UserAPIMixin
    ],
    created() {
      if(!this.$store.getters.basket) {
        this.toast('잘못된 접근입니다.');
        this.$router.back();
      }
    },
    data() {
      return {
        order: {
          service_name: '',
          key: ''
        },
        vErr: {
          name: false,
          key: false
        },
        err: ''
      }
    },
    methods: {
      validKey() {
        this.vErr.key = false;
        this.err = '';
        this.request.user.post('ui/super_builder/check_service', {key: this.order.key}).then(res=>{
          if(res.data.result) {
            this.vErr.key = true;
            this.err = '다른 주소를 입력해주세요.';
          }
        })
      },
      onKeyupServiceId: function (e) {
        // if(/[0-9a-z]/.test(e.target.value[e.target.value.length-1])) {
        //   this.params.service_id = e.target.value;
        // }

        // eslint-disable-next-line no-useless-escape
        let regExp = /[\{\}\[\]\/?_.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
        // 특수문자 예외처리
        if (regExp.test(e.target.value)) {
          this.order.key = e.target.value.replace(regExp, '');
        } else if (e.target.value.length > 0 && /[a-z]/.test(e.target.value[0]) && /[0-9a-z]/.test(e.target.value[e.target.value.length-1])) {
          this.order.key = e.target.value;
        } else {
          this.order.key = '';
        }
        this.order.key = this.order.key.toLowerCase();
      },
      clickOrder() {
        if(this.order.service_name === '') {
          this.toast('서비스명을 먼저 입력해주세요');
          this.vErr.name = true;
          return;
        } else if(this.order.key === '') {
          this.toast('서비스 주소를 입력해주세요');
          this.vErr.key = true;
          return;
        } else if(this.vErr.key) {
          this.toast('다른 서비스 주소를 입력해주세요.');
          return;
        }

        let params = this.$store.getters.basket;
        Object.assign(params, this.order);

        this.request.user.post('launchpack/v1/theme/order_new', {order: params});

        this.$router.replace(`/processing?skey=${this.order.key}`);
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .trial-account-container
    padding 160px 0
  .input-sub3
    border 1px solid #e3e3e3
  .input-error
    border 1px solid #ff6600

  .err
    color red
    font-size 11px
    margin-top 4px
    margin-left 4px

  .header-mobile
    display none

  @media (max-width:1024px)
    .trial-account-container
      padding 20px 0

    .header-mobile
      display flex
      justify-content flex-end
      padding 20px 16px
      i
        font-size 28px
        color $sub3


</style>
